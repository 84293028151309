import { Key, useContext } from "react";
import { Grid, View } from "@adobe/react-spectrum";

import ContentUI from "../content/Content";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import LoadingUI from "../loadingUI/LoadingUI";
import NotSignedIn from "../notSignedIn/NotSignedIn";

import { AuthContext } from "../../contexts/Auth.context";
import { ContentProvider } from "../../contexts/Content.context";

type TProps = {
    authState: {
        isIMSInitialized: boolean;
        isSignedIn: boolean;
    };
    isProfileMenuLoaded: boolean;
    locale: string;
};

const Home = ({ authState, isProfileMenuLoaded, locale }: TProps) => {

    const { setLocale } = useContext(AuthContext);

    const handleLanguageChange = (e: Key) => {
        setLocale(e.toString());
    }
    
    if(authState.isIMSInitialized) {
        if(authState.isSignedIn === true) {
          // Need to wait loading page till Profile Menu is initialized
          if(isProfileMenuLoaded) {
            return (
                <Grid
                    areas={['header', 'content', 'footer']}
                    rows={['auto', '1fr', 'auto']}
                    height="100vh"
                >
                    <View gridArea="header" backgroundColor="gray-900">
                        <Header />
                    </View>
                    <ContentProvider>
                        <View gridArea="content" backgroundColor="gray-50">
                            <ContentUI locale={locale} />
                        </View>
                    </ContentProvider>
                    <View gridArea="footer" backgroundColor="gray-50">
                        <Footer
                            handleLanguageChange={handleLanguageChange}
                            locale={locale}
                        />
                    </View>
                </Grid>
            );
          }
          else {
            // Loading view till ProfileMenu is initialized
            return (
                <Grid areas={['header', 'content', 'footer']}>
                    <View gridArea="header" isHidden>
                      <Header />
                    </View>
                    <View gridArea="content">
                      <LoadingUI />
                    </View>
                </Grid>
            );
          }
        }
        else {
            // Redirect to SUSI to sign in
            return (
                <Grid areas={['header', 'content', 'footer']}>
                    <View gridArea="header" isHidden>
                        <Header />
                    </View>
                    <NotSignedIn />
                </Grid>
            );
        }
      }
      else {
        // IMS is not initialized yet
        return (
            <Grid areas={['header', 'content', 'footer']}>
                <View gridArea="header" isHidden>
                    <Header />
                </View>
                <View gridArea="content">
                    <LoadingUI />
                </View>
            </Grid>
        );
      }
};

export default Home;