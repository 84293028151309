import {
      ProgressCircle,
      Flex
} from '@adobe/react-spectrum';

const LoadingUI = () => {
      return (
            <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100vh"
            >
                  <ProgressCircle
                        aria-label="Loading"
                        isIndeterminate />
            </Flex>
      )
};

export default LoadingUI;
