import { FormattedMessage } from "react-intl";
import{ Text } from '@adobe/react-spectrum';

import './InputFileStatus.styles.css';

type TProps = {
    selectedFile: File | null;
};

const inputFileStatus = ({ selectedFile }: TProps) => {

    return (
        <span className="inputFileStatus">
            <Text>
            {
              selectedFile === null ?
              <FormattedMessage
                id="52520"
                defaultMessage="No File Chosen"
                description="Display string for file selection status"
              />
              : selectedFile.name
            }
            </Text>
          </span>
    );
};

export default inputFileStatus;