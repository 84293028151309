import { FormattedMessage } from "react-intl";
import { Content } from "@adobe/react-spectrum";

type TProps = {
    checkingFileContent: boolean;
    firstLoad: boolean;
    isJSONFileType: boolean;
    isJSON: boolean;
};

const JsonCheckStatus = ({ checkingFileContent, firstLoad, isJSONFileType, isJSON }: TProps) => {
    return (
        firstLoad
          ? <></>
          : !isJSONFileType
            ? <div style={{color:"#A80000"}}>
                <Content marginTop="size-150">
                  <FormattedMessage
                    id="1284e"
                    defaultMessage="Choose a valid license request file."
                    description="Response for providing invalid JSON file"
                  />
                </Content>
              </div>
            : (checkingFileContent)
              ? <Content marginTop="size-150">
                  <FormattedMessage
                    id="cddcd"
                    defaultMessage="Please wait."
                    description="Loading string while checking if content of input file is JSON"
                  />
                </Content>
              : isJSON
                ? <></>
                : <div style={{color:"#A80000"}}>
                    <Content marginTop="size-150">
                      <FormattedMessage
                        id="1284e"
                        defaultMessage="Choose a valid license request file."
                        description="Response for providing invalid JSON file"
                      />
                    </Content>
                  </div>
    );
};

export default JsonCheckStatus;