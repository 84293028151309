import { FormattedMessage } from 'react-intl';
import { Content } from '@adobe/react-spectrum';


type TProps = {
  formSubmitted: boolean;
  loadingResponse: boolean;
  errorType: {
    fetchError: boolean;
    timeoutError: boolean;
    offlineActivationNotAllowedError: boolean;
  };
};

const FormResponse = ({ formSubmitted, loadingResponse, errorType }: TProps) => {

  const { fetchError, timeoutError, offlineActivationNotAllowedError } = errorType;

  if(!formSubmitted) {
    return <></>;
  }

  if(loadingResponse === true) {
    return (
      <Content marginTop="size-150">
        <FormattedMessage
          id="6921b"
          defaultMessage="Loading..."
          description="Loading string while waiting for response from server"
        />
      </Content>
    )
  }
  else {
    if(fetchError === true) {
      if(timeoutError === true) {
        return (
          <div style={{color:"#A80000"}}>
              <Content marginTop="size-150">
                <FormattedMessage
                  id="69408"
                  defaultMessage="An error has occurred while generating the license response code as the time to generate the response file has exceeded 72 hours. Re-generate the license request file in the ColdFusion Administrator."
                  description="Error message for 72 hours timeout"
                />
              </Content>
          </div>
        )
      }
      else if(offlineActivationNotAllowedError === true) {
        return (
          <div style={{color:"#A80000"}}>
              <Content marginTop="size-150">
                <FormattedMessage
                  id="04785"
                  defaultMessage="You cannot activate a Virtual Core license offline. To activate ColdFusion, connect to the internet from your server and retry activation."
                  description="Error message for offline mode activation not allowed"
                />
              </Content>
          </div>
        )
      }
      else {
        return (
          <div style={{color:"#A80000"}}>
              <Content marginTop="size-150">
                <FormattedMessage
                  id="cfc74"
                  defaultMessage="An error has occurred while generating the license response code. Re-try or generate a new license request file in the ColdFusion Administrator."
                  description="Error message for generic fetch failure"
                />
              </Content>
          </div>
        )
      }
    }
    else {
      return (
        <div style={{display: "None"}} />
      )
    }
  }
};

export default FormResponse;