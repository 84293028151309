import { FormattedMessage } from "react-intl";
import {
    Button,
    Icon,
    Text,
} from "@adobe/react-spectrum";

import downloadIcon from './../../images/download-icon.png';

type TProps = {
    buttonDisabled: boolean;
    handleSubmit: () => void;
};

const GenerateButton = ({ buttonDisabled, handleSubmit }: TProps) => {

    return (
        <Button
            variant="cta"
            type="submit"
            onPress={handleSubmit}
            isDisabled={buttonDisabled}
            marginTop="size-250"
        >
            <Text>
                <FormattedMessage
                    id="bd0e4"
                    defaultMessage="Generate and Download Response Code"
                    description="String for button for Generate response code"
                />
            </Text>
            <Icon>
                <img src={downloadIcon} alt="Download icon" />
            </Icon>
        </Button>
    );
};

export default GenerateButton;