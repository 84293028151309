import { useContext } from 'react';

import { AuthContext } from './contexts/Auth.context';

import Home from './components/home/Home';

import { Provider, defaultTheme } from '@adobe/react-spectrum';
import {IntlProvider} from "react-intl";

import locales from './locales';

const App = () => {

  const { authState, isProfileMenuLoaded, locale } = useContext(AuthContext);

  const messages = locales[locale];

  return (
    <Provider theme={defaultTheme} colorScheme="light" locale={locale}>
      <IntlProvider locale={locale} messages={messages}>
        <Home
          authState={authState}
          isProfileMenuLoaded={isProfileMenuLoaded}
          locale={locale}
        />
      </IntlProvider>
    </Provider>
  );
};

export default App;