import { FormattedMessage } from 'react-intl';
import {
  View,
  Heading,
  Flex,
  Image
} from '@adobe/react-spectrum';

import logo from './../../images/Adobe-Logo.png';

import './Header.styles.css';

const Header = () => {

  return (
    <Flex
      direction="row"
      maxWidth="1145px"
      width="80%"
      marginStart="auto"
      marginEnd="auto"
      minHeight="size-800"
    >
      <Image
        src={logo}
        alt="Adobe, Inc."
        width="size-350"
        minWidth="size-350"
        height="size-350"
        minHeight="size-350"
        marginTop="auto"
        marginBottom="auto"
      />
        <Heading
          id="content"
          level={1}
          marginStart="size-225"
          marginTop="auto"
          marginBottom="auto"
        >
          <FormattedMessage
            id="fdb95"
            defaultMessage="Adobe ColdFusion Offline Serial Activation"
            description="Header"
          />
        </Heading>
      <View
        marginStart="auto"
        marginTop="auto"
        marginBottom="auto">
        <div id="profile-menu-div" />
      </View>
    </Flex>
  )
};

export default Header;