import { createContext, useState, ReactNode, Dispatch, SetStateAction } from "react";

type Props = {
    children: ReactNode;
};

type ContentContextType = {
    contentState: {
        selectedFile: File | null;
        isJSON: boolean;
        isJSONFileType: boolean;
        requestJSON: string | null;
        formSubmitted: boolean;
        loadingResponse: boolean;
        checkingFileContent: boolean;
        fetchError: boolean;
        offlineActivationNotAllowedError: boolean;
        timeoutError: boolean;
        buttonDisabled: boolean;
        firstLoad: boolean;
    };
    setContentState: Dispatch<SetStateAction<{
        selectedFile: File | null;
        isJSON: boolean;
        isJSONFileType: boolean;
        requestJSON: string | null;
        formSubmitted: boolean;
        loadingResponse: boolean;
        checkingFileContent: boolean;
        fetchError: boolean;
        offlineActivationNotAllowedError: boolean;
        timeoutError: boolean;
        buttonDisabled: boolean;
        firstLoad: boolean;
    }>>;
};

export const ContentContext = createContext<ContentContextType>({
    contentState: {
        selectedFile: null,
        isJSON : false,
        isJSONFileType : false,
        requestJSON : null,
        formSubmitted : false,
        loadingResponse : false,
        checkingFileContent : false,
        fetchError : false,
        offlineActivationNotAllowedError : false,
        timeoutError : false,
        buttonDisabled : true,
        firstLoad : true,
    },
    setContentState: () => {},
});

export const ContentProvider = ({ children }: Props) => {
    
    const [ contentState, setContentState ] = useState<ContentContextType['contentState']>({
        selectedFile: null,
        isJSON : false,
        isJSONFileType : false,
        requestJSON : null,
        formSubmitted : false,
        loadingResponse : false,
        checkingFileContent : false,
        fetchError : false,
        offlineActivationNotAllowedError : false,
        timeoutError : false,
        buttonDisabled : true,
        firstLoad : true,
    })

    const value = {
        contentState,
        setContentState,
    };

    return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
}