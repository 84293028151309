import { Key } from 'react';
import {
  Heading,
  Content,
  Link,
  View,
} from '@adobe/react-spectrum';
import { FormattedMessage } from 'react-intl';

import Form from '../form/Form';

type TProps = {
  locale: Key;
};

type Links = {
  [key: string]: {
    [key: string]: string;
  };
};

const LINKS: Links = {
  tou: {
    en: process.env.REACT_APP_TOU_EN ?? "placeholder for en tou link",
    ja: process.env.REACT_APP_TOU_JA ?? "placeholder for ja tou link",
  },
  eula: {
    en: process.env.REACT_APP_EULA_EN ?? "placeholder for en eula link",
    ja: process.env.REACT_APP_EULA_JA ?? "placeholder for ja eula link",
  },
};

const ContentUI = ({ locale }: TProps) => {

  return (
    <View
      marginStart="auto"
      marginEnd="auto"
      width="80%"
      maxWidth="1145px"
    >
      <View
        marginBottom="size-700"
        marginTop="size-700"
      >
        <Content>
          <FormattedMessage
            id="f2f79"
            defaultMessage="This website is available to Adobe ColdFusion customers. Use of this website signifies your agreement to the <tou>Terms of Use (Adobe General Terms of Use)</tou> and <eula>ColdFusion EULA</eula>."
            description="Website summary string"
            values={{
              tou: (chunks) => (
                  <Link isQuiet variant="primary">
                    <a href={LINKS.tou[locale]}>
                      {chunks}
                    </a>
                  </Link>
              ),
              eula: (chunks) => (
                  <Link isQuiet variant="primary">
                    <a href={LINKS.eula[locale]}>
                      {chunks}
                    </a>
                  </Link>
              )
            }}
          />
        </Content>
      </View>
      <View marginBottom="size-700">
        <Heading
          level={1}
          marginBottom="size-50"
        >
          <FormattedMessage
                id="22af8"
                defaultMessage="Generate Response Code"
                description="SignedIn Header Generate Response Code heading"
          />
        </Heading>
        <Content >
          <FormattedMessage
            id="51aeb"
            defaultMessage="To complete offline activation, generate a response code on this page, and apply the same on your ColdFusion server."
            description="SignedIn description for generate response code"
          />
        </Content>
      </View>
      <Form />
    </View>
  );
};

export default ContentUI;