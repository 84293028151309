import { Key, useState } from 'react';
import { FormattedMessage} from 'react-intl';
import {
  View,
  Divider,
  Flex,
  ActionButton,
  Text,
  Link,
  MenuTrigger,
  Menu,
  Item
} from '@adobe/react-spectrum';
import GlobeGrid from '@spectrum-icons/workflow/GlobeGrid';

import './Footer.styles.css';

type TProps = {
  handleLanguageChange: (e: Key) => void;
  locale: string;
};

type Link = {
  [key: string]: string;
};

const ARROW_STYLE_CLASSES: {
  buttonArrowDown: string;
  buttonArrowUp: string;
} = {
  buttonArrowDown: 'buttonArrowDown',
  buttonArrowUp: 'buttonArrowUp',
};

const PRIVACY_LINK: Link = {
  en: process.env.REACT_APP_PRIVACYLINK_EN ?? "https://www.adobe.com/privacy/policy.html",
  ja: process.env.REACT_APP_PRIVACYLINK_JA ?? "https://www.adobe.com/go/privacy_policy_jp",
};

// Currently, there is no localized rights URL to point to. Leaving the provision here if it becomes available later.
const RIGHTS_LINK: Link = {
  en: process.env.REACT_APP_RIGHTSLINK_EN ?? "https://www.adobe.com/go/ca-rights",
  ja: process.env.REACT_APP_RIGHTSLINK_JA ?? "https://www.adobe.com/go/ca-rights",
};

export const Footer = ({ handleLanguageChange, locale }: TProps) => {

  const [ arrowID, setArrowID ] = useState(ARROW_STYLE_CLASSES.buttonArrowDown);

  const changeArrowDirection = () => {
    switch(arrowID) {
      case ARROW_STYLE_CLASSES.buttonArrowDown:
        setArrowID(ARROW_STYLE_CLASSES.buttonArrowUp);
        break;
      case ARROW_STYLE_CLASSES.buttonArrowUp:
        setArrowID(ARROW_STYLE_CLASSES.buttonArrowDown);
        break;
    }
  };

  return (
    <View>
      <Divider
        width="98%"
        size="M"
        marginStart="auto"
        marginEnd="auto"
      />
      <Flex
        direction="row"
        width="80%"
        maxWidth="1145px"
        alignItems="center"
        height="size-600"
        marginStart="auto"
        marginEnd="auto"
        wrap
      >
        <MenuTrigger onOpenChange={changeArrowDirection}>
          <ActionButton
            isQuiet>
            <GlobeGrid
              UNSAFE_style={{paddingLeft : '0'}}
              aria-label='Globe icon'/>
            <Text id={arrowID}>
              <span id='buttonLabel'>
                <FormattedMessage
                  id="4bb9b"
                  defaultMessage="Change region"
                  description="String for lanuage change menu"
                />
              </span>
            </Text>
          </ActionButton>
          <Menu onAction={handleLanguageChange}>
            <Item key="en">English</Item>
            <Item key="ja">日本</Item>
          </Menu>
        </MenuTrigger>
        <Flex
          direction="row"
          alignItems="center"
          marginStart="auto"
          wrap
        >
          <Text
            id="copyright"
            marginStart="size-225"
            marginTop="auto"
            marginBottom="auto"
          >
            <span className="footerFont">
              {"Copyright © " + new Date().getFullYear() + " Adobe. All rights reserved."}
            </span>
          </Text>
          <Text
            id="separator"
            marginX="size-100"
            marginY="size-0"
          >
            /
          </Text>
          <Link
            isQuiet
            variant="secondary"
            marginTop="auto"
            marginBottom="auto"
          >
            <a href={PRIVACY_LINK[locale]}>
              <Text>
                <span className="footerFont">
                <FormattedMessage
                  id="4a4ee"
                  defaultMessage="Privacy"
                  description="String for Privacy link"
                />
                </span>
              </Text>
            </a>
          </Link>
          <Text
            id="separator"
            marginX="size-100"
            marginY="size-0"
          >
            /
          </Text>
          <Link
            isQuiet
            variant="secondary"
            marginTop="auto"
            marginBottom="auto"
            marginEnd="size-200"
          >
            <a href={RIGHTS_LINK[locale]}>
              <Text>
                <span className="footerFont">
                <FormattedMessage
                  id="RafBwL"
                  defaultMessage="Do not sell or share my personal information"
                  description="String for Rights link"
                />
                </span>
              </Text>
            </a>
          </Link>
        </Flex>
      </Flex>
    </View>
  )
}

export default Footer;