import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { AdobeIMS } from "@identity/imslib";
import { IAdobeIdData } from "@identity/imslib/adobe-id/IAdobeIdData";
import { IEnvironment } from "@identity/imslib/adobe-id/IEnvironment";

import { IMS_CLIENT_ID, IMS_ENVIRONMENT, PROFILEMENU_BASEURL } from './../utils/constants';

type Navigator = {
  userLanguage?: string;
  browserLanguage?: string;
  languages: readonly string[];
  language: string;
};

type Props = {
  children: ReactNode;
};

declare global {
  interface Window {
    adobeid?: IAdobeIdData;
    _env_: {
      IMS_CLIENT_ID: string;
      IMS_ENVIRONMENT: IEnvironment;
      PROFILEMENU_BASEURL: string;
      CLAUS_URL: string;
    };
    adobeProfile: any;
    adobeIMS: AdobeIMS;
  }
};

const navigator: Navigator = window.navigator;

type AuthContextType = {
  authState: {
    isIMSInitialized: boolean;
    isSignedIn: boolean;
  };
  setAuthState: Dispatch<SetStateAction<{
    isIMSInitialized: boolean;
    isSignedIn: boolean;
  }>>;
  isProfileMenuLoaded: boolean;
  setIsProfileMenuLoaded: Dispatch<SetStateAction<boolean>>;
  locale: string;
  setLocale: Dispatch<SetStateAction<string>>;
};

export const AuthContext = createContext<AuthContextType>({
    authState: {
        isIMSInitialized: false,
        isSignedIn: false,
    },
    setAuthState: () => null,
    isProfileMenuLoaded: false,
    setIsProfileMenuLoaded : () => null,
    locale : navigator.language
             ? navigator.language.slice(0,2).toLowerCase()
             : (navigator.userLanguage
               ? navigator.userLanguage.slice(0,2).toLowerCase()
               : "en"),
    setLocale: () => null,
});

export const AuthProvider = ({ children }: Props) => {

    const navLocale = navigator.language
                        ? navigator.language.slice(0,2).toLowerCase()
                        : (navigator.userLanguage
                          ? navigator.userLanguage.slice(0,2).toLowerCase()
                          : "en");

    const [ authState, setAuthState ] = useState({
        isIMSInitialized: false,
        isSignedIn: false,
    });
    const [ isProfileMenuLoaded, setIsProfileMenuLoaded ] = useState(false);
    const [ locale, setLocale ] = useState(navLocale);

    const scriptErrorHandler = () => {
        document.body.innerHTML = '<p style="margin-left: 50px">We are unable to load any pages, please check your computer’s network connection.</p>'
    };

    // Initialize IMS and Profile Menu
    useEffect(() => {
        window.adobeid = {
          client_id : IMS_CLIENT_ID,
          scope : "AdobeID,openid",
          locale : 'en_US',
          environment : IMS_ENVIRONMENT,
          autoValidateToken: true,
          onAccessToken: null,
          onReauthAccessToken: null,
          onError: scriptErrorHandler,
          onAccessTokenHasExpired: null,
          onReady : () => {
            // Load ProfileMenu CSS
            var link = document.createElement('link');
            link.type = "text/css";
            link.rel= "stylesheet";
            link.onerror = () => {
              scriptErrorHandler() 
            };
            link.href=PROFILEMENU_BASEURL + "/adobe-profile/latest/adobe-profile.min.css";
            document.head.append(link);
            // Load ProfileMenu JS
            var script = document.createElement('script');
            script.onerror = () => {
              scriptErrorHandler() 
            };
            script.onload =  () => {
              // Initialize ProfileMenu
              window.adobeProfile.init({
                  target: document.querySelector('#profile-menu-div'),
                  locale: locale === 'ja' ? 'jp' : locale
              });
              // For showing signed In view only after Profile Menu is loaded
              window.adobeProfile.addEventListener(window.adobeProfile.events.profile_ready, function () {
                setIsProfileMenuLoaded(true);
              });
              window.adobeProfile.addEventListener(window.adobeProfile.events.sign_out, function () {
                window.adobeIMS.signOut()
              });
              setAuthState(prevState => ({
                ...prevState,
                isIMSInitialized: true,
                isSignedIn: window.adobeIMS.isSignedInUser(),
              }));
            };
            script.src= PROFILEMENU_BASEURL + "/adobe-profile/latest/adobe-profile.min.js";
            document.head.append(script);
          }
        };
        window.adobeIMS = new AdobeIMS(window.adobeid);
        window.adobeIMS.initialize(); // TO DO: Handle the promise returned
      }, []);

    const value = {
        authState,
        setAuthState,
        isProfileMenuLoaded,
        setIsProfileMenuLoaded,
        locale,
        setLocale,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};