import { ChangeEvent, MouseEvent, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Heading,
  Content,
  View,
  Button,
  Text,
  Flex,
  Icon
} from '@adobe/react-spectrum';
import { saveAs } from 'file-saver';

import { ContentContext } from '../../contexts/Content.context';

import InputFileStatus from '../inputFileStatus/InputFileStatus';
import JsonCheckStatus from '../jsonCheckStatus/JsonCheckStatus';
import GenerateButton from '../generateButton/GenerateButton';
import FormResponse from '../formResponse/FormResponse';

import { CLAUS_URL } from '../../utils/constants';

import uploadIcon from './../../images/upload-icon.png';

import './Form.styles.css';

const Form = () => {

  const { contentState, setContentState } = useContext(ContentContext);

  const {
    selectedFile,
    firstLoad,
    isJSONFileType,
    checkingFileContent,
    isJSON,
    buttonDisabled,
    formSubmitted,
    loadingResponse,
    fetchError,
    requestJSON,
    timeoutError,
    offlineActivationNotAllowedError,
  } = contentState;

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleSubmit = () => {

    setContentState(prevState => ({
      ...prevState,
      loadingResponse : true,
      formSubmitted : true,
      buttonDisabled : true,
      firstLoad : true,
      selectedFile : null,
      isJSONFileType : false,
      isJSON : false
    }));

    // Not handling the null case as IMS fetches a new token before a token expires. Null case is for those client ids whose default behaviour is to not fetch a token before expiry.
    // In the event that token is infact not valid, response generation fails (rare edge case) which will be resolved on a page refresh.
    const authorization = window.adobeIMS.getAccessToken()?.token as string;
    const requestInit: RequestInit = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key' : 'Coldfusion',
        'Authorization' : authorization,
      },
      body: requestJSON
    };

    let endpoint = '/v1/activateoffline'; // default endpoint for CF2021
    if(requestJSON != null) {
      let request = JSON.parse(requestJSON);
      let cf_version:string = request?.data?.cf_version ?? "";

      // TODO: throw an error if cf_version is empty string, likely means requestJSON file is invalid

      if(cf_version.startsWith("2023")) {
        endpoint = '/v2/activateoffline'; // endpoint for CF2023
      }

    }

    fetch(CLAUS_URL + endpoint, requestInit)
    .then(response => {

      if(response.ok) {
        return response.json();
      }
      else {
        return response.json().then(text => {
          // Handling errors as two kinds: 72 hour timeout(101018 error code) and other errors
          let error = "";
          if(text.errorCode === "101018") {
            error = "101018";
          }
          else if(text.errorCode === "101017") {
            error = "101017";
          }
          throw new Error(error)});
      }
    })
    .then((response) =>{

      const jsonString = JSON.stringify(response);
      const blob = new Blob([jsonString], {type: "application/json"});

      saveAs(blob, "ResponseCode.json");

      setContentState(prevState => ({
          ...prevState,
          loadingResponse : false,
      }));
    })
    .catch(err => {
      if(err.message === "101018") {
        setContentState(prevState => ({
          ...prevState,
          loadingResponse : false,
          fetchError : true,
          timeoutError : true
        }));
      }
      else if(err.message === "101017") {
        setContentState(prevState => ({
          ...prevState,
          loadingResponse : false,
          fetchError : true,
          offlineActivationNotAllowedError : true
        }));
      }
      else {
        setContentState(prevState => ({
          ...prevState,
          loadingResponse : false,
          fetchError : true
        }));
      }
    })
  };
  
  const handleBtnClick = () => {
    /*Collecting node-element and performing click*/
    if(inputFileRef.current) {
      inputFileRef.current.click();
    }
  }

  const checkIfJSONFileType = (item: File) => {

    let val = false;

    if(item.type === "application/json") {
      val = true;
    }

    return val;
  };

  const checkIfJSON = (item: File) => {

    const reader = new FileReader();
    let checkJSON = false;
    let content: string | null;
    let btnDisabled = true;

    reader.onload = (e) => {

      content = e.target?.result?.toString() ?? '';

      try {
        JSON.parse(content);
        checkJSON = true;
        btnDisabled = false;
      }
      catch (e) {
        checkJSON = false;
        content = null;
      }

      setContentState(prevState => ({
        ...prevState,
        selectedFile : item,
        isJSONFileType : true,
        isJSON : checkJSON,
        requestJSON : content,
        checkingFileContent : false,
        formSubmitted : false,
        buttonDisabled : btnDisabled,
        firstLoad : false
      }));
    }

    reader.readAsText(item);
  };

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {

    setContentState(prevState => ({
      ...prevState,
      selectedFile : null,
      isJSON : false,
      isJSONFileType : false,
      requestJSON : null,
      checkingFileContent : false,
      buttonDisabled : true,
      fetchError : false,
      timeoutError : false,
      offlineActivationNotAllowedError : false,
      firstLoad : true,
      formSubmitted : false
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {

    const { files } = event.target;
   
    if (files) {     
      const file = files[0];

      if (files["length"] === 1) {
        //checking if file type is .json
        let jsonFileType = checkIfJSONFileType(file);
        let loading = false;
        //checking if content is valid JSON
        if(jsonFileType) {
          checkIfJSON(file);
          loading = true;
        }
        setContentState(prevState => ({
          ...prevState,
          selectedFile : file,
          isJSONFileType : jsonFileType,
          checkingFileContent : loading,
          formSubmitted : false,
          firstLoad : false
        }));
      }
      else {
        setContentState(prevState => ({
          ...prevState,
          selectedFile : null,
          isJSONFileType : false,
          isJSON : false,
          firstLoad : true,
          buttonDisabled : true
        }));
      }
    }
  };

  return (
    <View>
      <View marginBottom="size-700">
        <Heading level={2} marginBottom="size-50">
          <FormattedMessage
            id="78d21"
            defaultMessage="License Request File"
            description="SignedIn Header License Request File Heading"
          />
        </Heading>
        <Content >
          <FormattedMessage
            id="4892e"
            defaultMessage="On the Licensing and Activation page in ColdFusion Administrator, specify the activation response file."
            description="SignedIn description about license file input"
          />
        </Content>
        <Flex
          direction="row"
          gap="size-100"
          marginTop="size-250"
        >
          <input
            id="upload"
            data-testid='file-input'
            type="file"
            accept=".json"
            ref={inputFileRef}
            onClick={handleClick}
            onChange={handleChange}
          />
          <Button
            variant="cta"
            type="button"
            onPress={handleBtnClick}>
            <Text>
              <FormattedMessage
                id="c906c"
                defaultMessage="Choose File"
                description="Button label for Choose File"
              />
            </Text>
            <Icon>
              <img src={uploadIcon} alt="Upload icon" />
            </Icon>
          </Button>
          <InputFileStatus selectedFile={selectedFile} />
        </Flex>
        <JsonCheckStatus
          checkingFileContent={checkingFileContent}
          firstLoad={firstLoad}
          isJSONFileType={isJSONFileType}
          isJSON={isJSON}
        />
      </View>
      <View marginBottom="size-700">
        <Heading level={2} marginBottom="size-50">
          <FormattedMessage
            id="ef7ef"
            defaultMessage="License Response Code"
            description="String for License Response Code Heading"
          />
        </Heading>
        <Content>
          <FormattedMessage
            id="46e89"
            defaultMessage='To download the response code, click "Generate and Download Response Code."'
            description="Description for generate response code instruction"
          />
        </Content>
        <GenerateButton
          buttonDisabled={buttonDisabled}
          handleSubmit={handleSubmit}
        />
        <FormResponse
          formSubmitted={formSubmitted}
          loadingResponse={loadingResponse}
          errorType={{ fetchError, timeoutError,offlineActivationNotAllowedError }}
        />
      </View>
    </View>
  )
};

export default Form;